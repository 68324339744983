// extracted by mini-css-extract-plugin
export var contact = "styles-module--contact---QyP3";
export var hero = "styles-module--hero--35X4C";
export var heroInner = "styles-module--heroInner--1V9cp";
export var pageContent = "styles-module--pageContent--2TSVP";
export var formBox = "styles-module--formBox--1Cq0e";
export var contactDetails = "styles-module--contactDetails--2L9x3";
export var formGroup = "styles-module--formGroup--2Ou3q";
export var submitBtn = "styles-module--submitBtn--27E1H";
export var detailItem = "styles-module--detailItem--2cizh";
export var detailLink = "styles-module--detailLink--1NHUy";
export var detail = "styles-module--detail--1FVcQ";
export var iconBox = "styles-module--iconBox--fhq-w";
export var socials = "styles-module--socials--28wLh";
export var socialLink = "styles-module--socialLink--2szlN";
export var subTitle = "styles-module--subTitle--rDhL-";