import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from './../components/Layout/index'
import Contact from '../components/Contact'

const ContactPage = ({ data }: any) => {
  return (
    <>
      <Helmet title={`Contact | ${data.site.siteMetadata.title}`} />
      <Layout>
        <Contact />
      </Layout>
    </>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
