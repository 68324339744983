import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
const styles = require("./styles.module.scss");

const Contact = () => {
  const { site }: any = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          socials {
            facebook
            linkedIn
            twitter
          }
        }
      }
    }
  `);
  interface FormData {
    name: string;
    email: string;
    message: string;
  }

  const [formData, setFormdata] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const [openToast, setToast] = useState<boolean>(false);

  const handleChange: React.ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormdata((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setToast(true);
  };

  const socialIcons: any = {
    twitter: <FontAwesomeIcon icon={faTwitter} size={"lg"} color='#5996f8' />,
    linkedIn: <FontAwesomeIcon icon={faLinkedin} size={"lg"} color='#5996f8' />,
    facebook: <FontAwesomeIcon icon={faFacebook} size={"lg"} color='#5996f8' />,
  };

  const renderSocialLinks: React.ReactNode[] = Object.entries(
    site.siteMetadata.socials
  ).map(
    ([key, value]): JSX.Element => (
      <li key={key} className={styles.socialLink}>
        <a href={value as string} target='_blank' rel='noreferrer noopenner'>
          {socialIcons[key]}
        </a>
      </li>
    )
  );

  return (
    <section className={styles.contact}>
      <div className={styles.hero}>
        <div className={styles.heroInner}>
          <h1>Contact Us</h1>
          <p>
            At Dewscope, we bring ideas to life through exceptional web and
            mobile apps development. If you're looking to start a project, need
            help with an existing website, or just have questions about our
            services, we're ready to assist you.
          </p>
        </div>
      </div>

      <div className={styles.pageContent}>
        <div className={styles.formBox}>
          <form noValidate>
            <div className={styles.formGroup}>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                name='name'
                value={formData.name}
                id='name'
                onChange={handleChange}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor='email'>Email Address</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                id='email'
                onChange={handleChange}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor='message'>Message</label>
              <textarea
                name='message'
                value={formData.message}
                id='message'
                onChange={handleChange}
                rows={10}
              />
            </div>

            <div className={styles.formGroup}>
              <button
                type='button'
                onClick={handleSubmit}
                className={styles.submitBtn}
              >
                Send message
              </button>
            </div>
          </form>
        </div>

        <div className={styles.contactDetails}>
          <h2 className={styles.subTitle}>
            Stop by our office, <br /> or give us a call
          </h2>
          <ul>
            <li className={styles.detailItem}>
              <a
                href='https://maps.app.goo.gl/wPoi7yrNwotYsLrEA'
                rel='noreferrer noopener'
                target='_blank'
                className={styles.detailLink}
              >
                <span className={styles.iconBox}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} size='lg' />
                </span>
                <span className={styles.detail}>
                  3, Atbara Street, off Cairo Street, Wuse II, Abuja.
                </span>
              </a>
            </li>

            <li className={styles.detailItem}>
              <a href='mailto:info@dewscope.com' className={styles.detailLink}>
                <span className={styles.iconBox}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <span className={styles.detail}>info@dewscope.com</span>
              </a>
            </li>

            <li className={styles.detailItem}>
              <a href='tel:+2349093258545' className={styles.detailLink}>
                <span className={styles.iconBox}>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </span>
                <span className={styles.detail}>+234-9093-258-545</span>
              </a>
            </li>
          </ul>

          {/* <div className={styles.socials}>
            <h2 className={styles.subTitle}>Follow us on social media</h2>
            <ul>{renderSocialLinks}</ul>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
